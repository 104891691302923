import React from 'react';
import propTypes from 'prop-types';

import * as styles from './urlSection.module.css';
import ArrowRight from '@icons/arrow_right_main.svg';

const URL = ({ url }) => {
  return (
    <li>
      <a className={styles.link} href={url.href}>
        {url.text}
        <ArrowRight />
      </a>
    </li>
  );
};

URL.propTypes = {
  url: propTypes.object,
};

export const URLList = ({ list }) => {
  return (
    <div>
      <h3>{list.header}</h3>
      <ul className={styles.linksList}>
        {list.urls.map((url, index) => (
          <URL url={url} key={index} />
        ))}
      </ul>
    </div>
  );
};

URLList.propTypes = {
  list: propTypes.arrayOf(propTypes.object),
};

const URLSection = ({ lists }) => {
  return (
    <div className={`${styles.urlSectionContainer}`}>
      {lists.map((list, index) => (
        <URLList key={index} list={list} />
      ))}
    </div>
  );
};

export default URLSection;

URLSection.propTypes = {
  lists: propTypes.arrayOf(propTypes.object),
};

URLSection.defaultProps = {};
