import React from 'react';
import { graphql } from 'gatsby';

import SEO from '@components/seo';

import BackNavBar from '@src/components/backNavBar';
import Contact from '@components/contact';
import Hero from '../../components/hero';
import Layout from '@components/layout';
import DHLTermsAndConditions from '../../../static/DHL_Terms_And_Conditions.pdf';

import { URLList } from '@components/index-components/urlSection';

import * as styles from './blocket.module.css';

const RockerPayVillkor = ({ data }) => {
  const {
    contentfulPage: {
      pageHeader,
      pageHeaderDescription: { pageHeaderDescription },
    },
  } = data;

  const headLine = data.contentfulPage.headLine;
  const contactHeader = data.contentfulPage.pageContactHeader || '';
  const lists = {
    header: data.contentfulPage.pageContactHeader || '',
    urls: [
      {
        text: 'Villkor Rocker Pay',
        href: '/allmanna-villkor/rocker-pay/villkor',
      },
      {
        text: 'Villkor Faktura Delbetalning',
        href: '/allmanna-villkor/rocker-pay/faktura-delbetalning',
      },
      {
        text: 'Villkor Faktura Blanco',
        href: '/allmanna-villkor/rocker-pay/faktura-blanco',
      },
      {
        text: 'DHL uppdragsvillkor',
        href: DHLTermsAndConditions,
      }
    ],
  };

  return (
    <Layout>
      <SEO title={headLine} noIndex />
      <Hero
        breadcrumbs={[
          { path: '/', name: 'Hem' },
          { path: '/allmanna-villkor', name: 'Allmänna Villkor' },
        ]}
        title={pageHeader}
        subtitleMarkdown={pageHeaderDescription}
      />
      <div className={styles.contentWrapper}>
        <BackNavBar text="Tillbaka till villkor" back="/allmanna-villkor" />
        <div className={styles.urlSectionsContainer}>
          <URLList list={lists} />
        </div>
      </div>
      <Contact headerText={contactHeader} isLargeContact={true} />
    </Layout>
  );
};

export default RockerPayVillkor;

export const query = graphql`
  query RockerPayVillkor {
    contentfulPage(contentful_id: { eq: "5kVKOzDG8nZ05xnfhgeGTu" }) {
      headLine
      pageHeader
      pageHeaderDescription {
        pageHeaderDescription
      }
    }
  }
`;
